import React from 'react';
import styled from 'styled-components';

const Header = () => (
  <Container>
    <StyledLink>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="129"
        height="24"
        viewBox="0 0 129 24"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill="#000A17" transform="translate(-49 -354)">
            <g transform="translate(50 354)">
              <g>
                <path d="M85.504 9.454a.494.494 0 00-.494.494v2.715c0 .273.221.494.494.494h5.184c.272 0 .493-.221.493-.494V9.948a.494.494 0 00-.493-.494h-5.184zm25.098-8.393h-3.643a.74.74 0 00-.671.428l-2.886 6.184-2.886-6.184a.739.739 0 00-.671-.428h-3.643a.494.494 0 00-.435.726l5.142 9.642-5.142 9.642a.494.494 0 00.435.726h3.643c.287 0 .55-.167.67-.428l2.887-6.185 2.886 6.185a.74.74 0 00.67.428h3.644a.493.493 0 00.435-.726l-5.142-9.642 5.142-9.642a.493.493 0 00-.435-.726zm-19.174 0H80.073a.74.74 0 00-.74.74v19.255c0 .409.332.74.74.74h11.355a.494.494 0 00.494-.493v-2.715a.494.494 0 00-.494-.494h-7.652V4.764h7.652c.273 0 .494-.22.494-.494V1.555a.494.494 0 00-.494-.494zm35.137 0H113.73a.494.494 0 00-.494.494V4.27c0 .273.221.494.494.494h4.196v16.539c0 .272.221.494.494.494h3.456a.494.494 0 00.494-.494V4.764h4.196c.273 0 .494-.22.494-.494V1.555a.494.494 0 00-.494-.494zm-52.254 0H71.35a.493.493 0 00-.493.494V14.26L64.962 1.631a.987.987 0 00-.895-.57h-3.085a.987.987 0 00-.988.988v19.254c0 .272.222.494.494.494h2.962a.494.494 0 00.494-.494V8.597l5.893 12.63c.163.347.511.57.895.57h3.085a.988.988 0 00.988-.988V1.555a.493.493 0 00-.494-.494zM17.7 13.52a7.866 7.866 0 11-15.733 0V4.2c0-.408.31-.743.706-.783l14.16-1.416a.787.787 0 01.867.783v10.734zM17.306 0c-.08 0-.157.004-.235.012L2.125 1.506A2.36 2.36 0 000 3.855v9.664c0 5.43 4.402 9.833 9.833 9.833 5.43 0 9.833-4.403 9.833-9.833V2.359A2.36 2.36 0 0017.306 0zm27.292 1.061h-3.456a.493.493 0 00-.494.494v13.33c0 2.127-.999 3.209-2.962 3.209s-2.962-1.082-2.962-3.21V1.556a.494.494 0 00-.494-.494h-3.456a.494.494 0 00-.493.494v13.33c0 4.908 2.497 7.405 7.405 7.405s7.405-2.497 7.405-7.405V1.555a.493.493 0 00-.493-.494zM13.963 4.948a.736.736 0 00-.08.004l-8.257.826a.787.787 0 00-.71.783v6.958a4.916 4.916 0 109.834 0V5.735a.787.787 0 00-.787-.787zm41.172 4.506H49.95a.494.494 0 00-.494.494v2.715c0 .272.222.494.494.494h5.184a.494.494 0 00.493-.494V9.948a.494.494 0 00-.493-.494z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </StyledLink>
  </Container>
);

const Container = styled.header`
  height: 4rem;
  width: 100%;

  background-image: url(https://metac.nxtv.jp/img/static_resource/img/ubs/gridPattern-header.svg);
  background-color: ${({ theme }) => theme.background.secondary};
  background-size: auto 4rem;
  background-position: bottom center;
  background-repeat: repeat-x;
`;

const StyledLink = styled.a`
  display: block;
  height: 4rem;
  margin: 0 0 0 1rem;
  position: relative;

  & > svg {
    height: 1.5rem;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;

export default Header;
