import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';

export default createGlobalStyle`
  ${reset}

  html, body {
    background-color: ${({ theme }) => theme.background.standard};
    color: ${({ theme }) => theme.text.standard};
    font-feature-settings: "palt";
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-width: 320px;
    height: 100%;
    font-size: 16px;
    line-height: 1.7;
    font-family: -apple-system, BlinkMacSystemFont, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'メイリオ', Meiryo, "Helvetica Neue", Helvetica, Arial, Sans-Serif;

    @media (max-width: 640px) {
      font-size: 14px;
    }
  }

  div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,img, ul, li, header, footer, nav, section, article, a,p, blockquote, span,header,footer,nav,section,article,input,textarea, figure , figcaption, main, pre{
    box-sizing: border-box;
    word-wrap: break-word;
  }

  img {
    margin: 0;
  	padding: 0;
  	vertical-align: bottom;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
