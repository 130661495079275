import React from 'react';
import styled from 'styled-components';

import Header from './Header';

const withLayout = WrappedComponent => props => {
  return (
    <Container>
      <Header />
      <Content>
        <WrappedComponent {...props} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Content = styled.main`
  flex: 1 1 auto;
  position: relative;
  width: 100%;
`;

export default withLayout;
