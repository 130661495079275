const theme = {
  color: {
    primary: '#0fb9f5',
    secondary: '#fac819',
    tertiary: '#c22727',
  },
  background: {
    primary: '#000a17',
    standard: '#f6f8fa',
    secondary: '#e0e2e4',
    tertiary: '#fff',
  },
  fontSize: {
    h1: '2.125rem', // 34
    h2: '1.875rem', // 30
    lead1: '1.25rem', // 20
    lead2: '1.125rem', // 18
    standard: '1rem', // 16
    body2: '0.875rem', // 14
    note1: '0.75rem', // 12
  },
  text: {
    primary: 'rgb(0, 10, 23, 1)',
    primaryInverted: 'rgba(255, 255, 255, 1)',
    standard: 'rgba(0, 10, 23, .8)',
    secondary: 'rgba(0, 10, 23, .6)',
    tertiary: 'rgba(0, 10, 23, .4)',
    quaternary: 'rgba(0, 10, 23, .1)',
  },
};

export default theme;
