import React, { lazy, Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import { Layout } from 'src/components/Layout';
const TopComponent = lazy(() => import('src/containers/Top'));
const NotFound = lazy(() => import('src/containers/NotFound'));

const Routes = () => (
  <CookiesProvider>
    <Layout>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <Route exact path="/" component={TopComponent} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Layout>
  </CookiesProvider>
);

export default Routes;
